import React from 'react';
import { Link } from "react-router-dom";

const Thankyou = () => {
    return (
        <div className="thankyou">
                <div className="thankyoumessage">
                    <h1>Thank you for posting!</h1>
                    <Link to="/"> <button className="homebutton">Go Back</button> </Link>
            </div>
        </div>
    );
}

export default Thankyou;
