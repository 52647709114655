import React from "react";
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const Contact = () => {

  const form = useRef(null);
  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_6erncv7', 'template_2ehzirq', form.current, 'JvvyCJvP1UkyieKaF')
      .then((result) => {
          console.log(result.text);
          navigate("/thankyou");
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div>
      <div data-aos="fade-up" className="contactform">
        <div className="wavey">
          <div className="contactme">
            <span style={{ "--i": 1 }}>C</span>
            <span style={{ "--i": 2 }}>O</span>
            <span style={{ "--i": 3 }}>N</span>
            <span style={{ "--i": 4 }}>T</span>
            <span style={{ "--i": 5 }}>A</span>
            <span style={{ "--i": 6 }}>C</span>
            <span style={{ "--i": 7 }}>T</span>
          </div>
          <span style={{ "--i": 8 }}>M</span>
          <span style={{ "--i": 9 }}>E</span>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <div>
            <input
              type="text"
              name="user_name"
              className="inputinfo"
              placeholder="Name"
              required
            />
          </div>
          <div>
            <input
              type="email"
              name="user_email"
              className="inputinfo"
              placeholder="Email"
              required
            />
          </div>
          <div>
            <textarea
              name="message"
              placeholder="Write something..."
              required
            />
          </div>
          <div className="submit">
            <input type="submit" value="Send" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
