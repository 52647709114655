import React from 'react';

const Title = () => {
    return (
        <div>
            <div className="titleblurb">
                <h1>Hey there.</h1>
                <p>Nice to you meet you.</p>
                <p>My name is <span id="name">Nelson</span> and I am a software engineer in Sydney!</p>
            </div>
        </div>
    );
}

export default Title;
