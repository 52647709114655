import React from "react";

import letr from '../img/Letr.jpg';
import coffeedate from '../img/Coffeedates.jpg';
import ridlr from '../img/Ridlr.jpg';
import ttt from '../img/ttt.jpg';

//Logo Imports

import css from '../img/techstack/css.png';
import firebase from '../img/techstack/firebase.png';
import heroku from '../img/techstack/heroku.png';
import html from '../img/techstack/html.png';
import jquery from '../img/techstack/jquery.png';
import js from '../img/techstack/js.png';
import netlify from '../img/techstack/netlify.png';
import postgresql from '../img/techstack/postgresql.png';
import react from '../img/techstack/react.png';
import ruby from '../img/techstack/ruby.png';
import sass from '../img/techstack/sass.png';
import supabase from '../img/techstack/supabase.jpg';

const Projects = () => {
  return (
    <div>
      <div className="headwrapper">
        <p className="heading" id="pheading">
          Projects
        </p>
      </div>
      <div data-aos="fade-right" className="project">
        <div className="pimg">
          <a
            href="https://github.com/Nelf-dev/Letr"
            target="_blank"
            rel="noreferrer"
          >
            <img src={letr} alt="Project ScreenShot"></img>
          </a>
        </div>
        <div className="pinfo">
          <p id="subheading">Letr</p>
          <p>Team Collaboration using React and Firebase.</p>
          <p>
            An instant messaging webapp that allows users to add and send
            messages with support for mobile devices.
          </p>
          <p>
            Tech Stack :
            <img id="tech" src={html} alt="html" />
            <img id="tech" src={sass} alt="sass" />
            <img id="tech" src={react} alt="react" />
            <img id="tech" src={js} alt="js" />
            <img id="tech" src={firebase} alt="firebase" />
            <img id="tech" src={netlify} alt="netlify" />
          </p>
          <a
            href="https://storied-seahorse-83953f.netlify.app/"
            target="_blank"
            rel="noreferrer"
            className="demo"
          >
            Demo
          </a>
        </div>
      </div>

      <div data-aos="fade-left" className="project">
        <div className="pimg">
          <a
            href="https://github.com/Nelf-dev/Coffee-Dates"
            target="_blank"
            rel="noreferrer"
          >
            <img src={coffeedate} alt="Project ScreenShot"></img>
          </a>
        </div>
        <div className="pinfo">
          <p id="subheading">Coffee Date</p>
          <p>Team Project using React and Supabase.</p>
          <p>
            Inspired from tinder, this webapp allows mentees and mentors match
            via interactive swiping. Once both parties have connected, they can
            plan and chat using the in app messaging platform.
          </p>
          <p>
            Tech Stack :
            <img id="tech" src={html} alt="html" />
            <img id="tech" src={css} alt="css" />
            <img id="tech" src={react} alt="react" />
            <img id="tech" src={js} alt="js" />
            <img id="tech" src={postgresql} alt="postgresql" />
            <img id="tech" src={netlify} alt="netlify" />
            <img id="tech" src={supabase} alt="supabase" />
          </p>
          <a
            href="https://main--stunning-entremet-0abbec.netlify.app/"
            target="_blank"
            rel="noreferrer"
            className="demo"
          >
            Demo
          </a>
        </div>
      </div>

      <div data-aos="fade-right" className="project">
        <div className="pimg">
          <a
            href="https://github.com/Nelf-dev/Riddle"
            target="_blank"
            rel="noreferrer"
          >
            <img src={ridlr} alt="Project ScreenShot"></img>
          </a>
        </div>
        <div className="pinfo">
          <p id="subheading">Ridlr</p>
          <p>Solo Project using Ruby on rails making a CRUD based system.</p>
          <p>
            Tech Stack :
            <img id="tech" src={html} alt="html" />
            <img id="tech" src={css} alt="css" />
            <img id="tech" src={ruby} alt="ruby" />
            <img id="tech" src={js} alt="js" />
            <img id="tech" src={jquery} alt="jquery" />
            <img id="tech" src={postgresql} alt="postgresql" />
            <img id="tech" src={heroku} alt="heroku" />
          </p>
          <a
            href="https://riddlingriddler.herokuapp.com/"
            target="_blank"
            rel="noreferrer"
            className="demo"
          >
            Demo
          </a>
        </div>
      </div>

      <div data-aos="fade-left" className="project">
        <div className="pimg">
          <a
            href="https://github.com/Nelf-dev/Tic-Tac-Toe"
            target="_blank"
            rel="noreferrer"
          >
            <img src={ttt} alt="Project ScreenShot"></img>
          </a>
        </div>
        <div className="pinfo">
          <p id="subheading">Tic-Tac-Toe</p>
          <p>First Solo Project created during General Assembly</p>
          <p>
            Tech Stack:
            <img id="tech" src={html} alt="html" />
            <img id="tech" src={css} alt="css" />
            <img id="tech" src={js} alt="js" />
            <img id="tech" src={jquery} alt="jquery" />
          </p>
          <a
            href="https://nelf-dev.github.io/Tic-Tac-Toe/"
            target="_blank"
            rel="noreferrer"
            className="demo"
          >
            Demo
          </a>
        </div>
      </div>
    </div>
  );
};

export default Projects;
