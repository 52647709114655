import React from 'react';
import pdf from '../src/nelson.pdf';

const Resume = () => {
    return (
        <div className="resume">
            <object aria-label="resume" data={pdf}/>
        </div>
    );
}

export default Resume;
