import { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';


// component imports
import Title from './components/Title';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';


// Image Imports
import email from './img/email.png';
import github from './img/github.png';
import linkedin from './img/linkedin.png';

function App() {

  const [isActive, setIsActive] = useState("hamburger");
  const [theme, setTheme] = useState("theme dark");
  const [isOn, setIsOn] = useState("");

  const title = useRef(null);
  const about = useRef(null);
  const projects = useRef(null);
  const contact = useRef(null);

  useEffect(() => {
    Aos.init({
      duration: 800,
    })
  }, []);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: 'smooth',
    });
    handleBurger();
    console.log("moved");
  }
  
  const handleBurger = () => {
    isActive === "hamburger" ? setIsActive("hamburger active") : setIsActive("hamburger");

    isOn === "" ? setIsOn("on") : setIsOn("");
  }

  const handleTheme = () => {
    theme === "theme" ? setTheme("theme dark") : setTheme("theme");
  }

  return (
    <div className={theme}>
      <nav className="navbar">
        <div className="icons">
          <div className="icon">
          <a href="https://github.com/Nelf-dev" rel="noreferrer" target="_blank"><img src={github} alt="github"/></a>
          </div>
          <div className="icon">
          <a href="https://linkedin.com/in/nelsonfeng" rel="noreferrer" target="_blank"><img src={linkedin} alt="linkedin"/></a>
          </div>
          <div className="icon">
          <img onClick={() => scrollToSection(contact)} src={email} alt="email"/>
          </div>
        </div>



        <div className="navlinks">
          
          <div className="mode" onClick={handleTheme}>
          </div>
          
          <div onClick={handleBurger} className={isActive}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
          <ul className={isOn}>
            <li className="wave" onClick={() => scrollToSection(title)}>
              <span style={ {"--i":1} }>H</span>
              <span style={ {"--i":2} }>O</span>
              <span style={ {"--i":3} }>M</span>
              <span style={ {"--i":4} }>E</span>
              </li>
            <li className="wave" onClick={() => scrollToSection(about)}>
              <span style={ {"--i":1} }>A</span>
              <span style={ {"--i":2} }>B</span>
              <span style={ {"--i":3} }>O</span>
              <span style={ {"--i":4} }>U</span>
              <span style={ {"--i":5} }>T</span>
            </li>
            <li className="wave" onClick={() => scrollToSection(projects)}>
              <span style={ {"--i":1} }>P</span>
              <span style={ {"--i":2} }>R</span>
              <span style={ {"--i":3} }>O</span>
              <span style={ {"--i":4} }>J</span>
              <span style={ {"--i":5} }>E</span>
              <span style={ {"--i":6} }>C</span>
              <span style={ {"--i":7} }>T</span>
              <span style={ {"--i":8} }>S</span>
            </li>
            <li className="wave" onClick={() => scrollToSection(contact)}>
              <span style={ {"--i":1} }>C</span>
              <span style={ {"--i":2} }>O</span>
              <span style={ {"--i":3} }>N</span>
              <span style={ {"--i":4} }>T</span>
              <span style={ {"--i":5} }>A</span>
              <span style={ {"--i":6} }>C</span>
              <span style={ {"--i":7} }>T</span>
            </li>
            <Link to="/resume" target="_blank" className="resumelink wave">
              <span style={ {"--i":1} }>R</span>
              <span style={ {"--i":2} }>E</span>
              <span style={ {"--i":3} }>S</span>
              <span style={ {"--i":4} }>U</span>
              <span style={ {"--i":5} }>M</span>
              <span style={ {"--i":6} }>E</span>
            </Link>
          </ul>
        </div>
      </nav>

      <div className="content">

        <div ref={title} className="title sections">
          < Title/>
        </div>

        <div ref={about} className="about sections">
          < About/>
        </div>

        <div ref={projects} className="projects sections">
          < Projects/>
        </div>

        <div ref={contact} className="contact sections">
          < Contact/>
        </div>

      </div>

    </div>
  );
}

export default App;
