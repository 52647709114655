import React from 'react';

import nelson from '../img/nelson.png';

const About = () => {
    return (
        <div>
            <div className="headwrapper">
                <p className="heading">About</p>
            </div>
            <div data-aos="fade-up" className="aboutblurb">
                <div className="aboutimg">
                <img src={nelson} alt="me"/>
                </div>
                <div className="aboutp">
                    <p>Hey! I'm Nelson </p>
                    <br/>
                    <p>I am a Sydney based software engineer. Recently I have graduated from General Assembly Australia, undertaking a 3 month course. Before that I used to work as a hotel night manager!</p>
                    <br/>
                    <p>During my time at General Assembly, I had experience with front and back end projects, collaborating with other developers and UX designers and honestly I have fallen in love with coding! I am available to full-time employment and open to collaborating!
                    </p>
                </div>
            </div>
        </div>
    );
}

export default About;
